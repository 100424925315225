const textConst = {
  iCorp: 'Secure',
  security: 'Security',
  learning: 'Learning',
  managementSystem: 'Management System',
  lms: 'Learning Management System',
  welcomeToTheText: 'Welcome to the',
  onlineLearningSystem: 'Online Learning System',
  enterEmailPasswordText:
    'Please enter your Email and Password to commence your training',
  logIn: 'Login',
  footerText: '© 2023 Secure Sol. All rights reserved',
  allRightsReserved: 'All rights reserved',
  enterYourEmail: 'Please enter your email address.',
  receiveLink: 'You will receive a link to create a new password via email.',
  backToLogin: 'Back to Login',
  forgotPassword: 'Forgot Password',
  resetPassword: 'Reset Password',
  welcome: 'Welcome',
  welcomeDescription:
    'This system has been designed to maximize your knowledge and understanding whilst you are working at Secure Sol Security',
  report: 'Course Reports',
  createNewPassword: 'Create New Password',
  enterYourPassword: 'Please enter your new password.',
  receiveLogInLink: 'You will receive a link to log in to your account.',
  newUser: 'New User',
  edit: 'Edit',
  preview: 'Preview',
  feedback: 'Show Feedback',
  assigncourse: 'Assign Course',
  add: 'Add',
  cancel: 'Cancel',
  user: 'User',
  import: 'Import',
  export: 'Export',
  reports: 'Report',
  categories: 'Categories',
  category: 'Category',
  sub: 'Sub',
  changePassword: 'Change Password',
  areYouSure: 'Are you sure?',
  summaryText: 'Summary Text',
  reminderMailText: 'Assign Courses',
  delete: 'Delete',
  sendremindermail: 'Send Remainder Mail',
  assessmentCloseTitle: 'Are you sure?',
  close: 'Close',
  areYouSureContent:
    'Do you really want to delete these records? This process cannot be undone.',
  areYouSureRemainderContent:
    'Do you really want to send remainder? This process cannot be undone.',
  areYouSureUserContent:
    'Do you really want to delete these user? This process cannot be undone.',
  areYouSureSectionContent:
    'Do you really want to delete these section? This process cannot be undone.',
  areYouSureEditSlideContent:
    'Do you really want to edit these slide? This process cannot be undone.',
  areYouSureSlideContent:
    'Do you really want to delete these slide? This process cannot be undone.',
  assessment: 'Assessment',
  questions: 'Questions',
  image: 'Image',
  pdf: 'PDF',
  answer: 'Answer',
  single: 'Single',
  multiple: 'Multiple',
  more: 'More',
  userList: ' User List',
  assessmentList: ' Assessment List',
  staffType: 'Staff Type',
  direct: 'Direct',
  indirect: 'Indirect',
  status: 'Status',
  active: 'Active',
  inactive: 'Inactive',
  new: 'New',
  assignCourse: 'Assign Course',
  downloadStandardProfile: 'Download Standard Profile',
  settings: 'Settings',
  courseSettings: 'Course Settings',
  dashboardSettings: 'Dashboard Settings',
  save: 'Save',
  assessmentCloseContent:
    'The assessment progress will be lost if you close the slideshow.',
  editSummary: 'Edit Summary',
};

export default textConst;

import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Radio } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import HookAutoComplete from '../../components/HookAutoComplete';
import HookRadioGroupField from '../../components/HookRadioGroupField';
import HookTextField from '../../components/HookTextfield';
import LearningQuestions, {
  questionItem,
} from '../../components/learning-questions/LearningQuestions';
import Loader from '../../components/Loader';
import LoadingButton from '../../components/LoadingButton';
import useDebounce from '../../hook/useDebounce';
import {
  useAddAssessmentMutation,
  useGetAllAssessmentCategoriesQuery,
  useGetAssessmentQuery,
  useUpdateAssessmentMutation,
  useUpdateAssessmentOrderMutation,
} from '../../store/apis/assessment';
import { notify } from '../../utils/commonFunctions';
import textConst from '../../utils/textConst';
import validationSchemas from '../../utils/validationSchemas';

const validationSchema = yup.object({
  name: validationSchemas.title,
  assessmentCategory: validationSchemas.assessmentCategory,
  questions: yup.array(
    yup.object({
      questionText: validationSchemas.question,
      type: yup.string().oneOf(['Single', 'Multiple']),
      choices: validationSchemas.assessmentQuestionChoice,
    })
  ),
  status: yup.string().required(),
});

const AddEditAssessment = () => {
  const navigate = useNavigate();

  const [searchVal, setSearchVal] = useState(null);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [isOrderChanged, setIsOrderChanged] = useState<any>(null);
  const [saveButtonClicked, setSaveButtonClicked] = useState<any>(null);

  const debouncedSearchValue = useDebounce(searchVal, 400);

  const {
    data: categoryData,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAllAssessmentCategoriesQuery(
    {
      search: debouncedSearchValue || '',
      isActive: true,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      assessmentCategory: null,
      questions: [],
      status: 'active',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });
  useEffect(() => {
    const errorClone: any = errors;
    const isRightAnswerMissing = errorClone?.questions?.find((item: any) => {
      return item?.choices?.type === 'atLeastOneRightChoice';
    });
    if (isRightAnswerMissing) {
      notify('Please select correct answer for each question', 'error');
    }
  }, [errors]);

  const { id } = useParams();
  useEffect(() => {
    if (!id) {
      setValue('questions', [questionItem]);
    }
  }, [setValue, id]);

  const {
    data: assessmentData,
    isLoading: isAssessmentLoading,
    isFetching: isAssessmentFetching,
    isSuccess: isAssessmentSuccess,
    isError: isAssessmentError,
    error: assessmentError,
  } = useGetAssessmentQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (id && (isAssessmentLoading || isLoading || isAssessmentFetching)) {
      setIsSetLoading(true);
    } else {
      setIsSetLoading(false);
    }
  }, [isAssessmentLoading, isLoading, isAssessmentFetching, id]);

  useEffect(() => {
    if (isAssessmentSuccess) {
      setValue('name', assessmentData?.name);
      setValue('assessmentCategory', assessmentData?.assessmentCategoryId);
      let questionClone = assessmentData?.questions?.length
        ? [...assessmentData.questions]
        : [];

      questionClone = questionClone?.map((item: any) => {
        const choiceCount = item?.choices?.length;
        return {
          ...item,
          option: { _id: choiceCount, name: `${choiceCount}` },
        };
      });

      setValue('questions', questionClone);
      setValue('status', assessmentData?.isActive ? 'active' : 'inactive');
      setIsSetLoading(false);
    }
  }, [assessmentData, isAssessmentSuccess, setValue]);

  const [
    addAssessment,
    {
      isLoading: isAddLoading,
      isSuccess: isAddSuccess,
      isError: isAddError,
      error: addError,
    },
  ] = useAddAssessmentMutation();

  const [
    updateAssessmentData,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
    },
  ] = useUpdateAssessmentMutation();

  const [
    updateAssessmentOrderData,
    {
      isLoading: isOrderUpdateLoading,
      isSuccess: isOrderUpdateSuccess,
      isError: isOrderUpdateError,
      error: updateOrderError,
    },
  ] = useUpdateAssessmentOrderMutation();

  useEffect(() => {
    if (
      isAddError ||
      isError ||
      isAssessmentError ||
      isUpdateError ||
      isOrderUpdateError ||
      updateOrderError
    ) {
      notify(
        addError?.data?.message ||
          error?.data?.message ||
          assessmentError?.data?.message ||
          // updateError?.data?.message ||
          // updateOrderError?.data?.message,
          'Something went wrong',
        'error'
      );
    }
  }, [
    addError,
    assessmentError?.data?.message,
    error?.data?.message,
    isAddError,
    // isUpdateError,
    isOrderUpdateError,
    // updateError,
    updateOrderError,
    isAssessmentError,
    isError,
  ]);

  // const onSubmit = (data: any) => {

  //   const isActive = data?.status === 'active';
  //   const finalQuestionData = data.questions?.map(
  //     (item: any, index: number) => {
  //       return {
  //         choices: item.choices?.map((choiceItem: any) => ({
  //           choiceText: choiceItem?.choiceText,
  //           isRight: choiceItem?.isRight,
  //         })),
  //         questionText: item.questionText.trim(),
  //         hint: item.hint.trim(),
  //         fileType: item?.fileType,
  //         type: item?.type,
  //         questionOrder: index + 1,
  //       };
  //     }
  //   );

  //   console.log("finalQuestionData====>", finalQuestionData);

  //   if (id) {
  //     const ques = data.questions.map((item: any, index: number) => {
  //       if (!item?._id) {
  //         return {
  //           ...item,
  //           questionOrder: index + 1,
  //           questionText: item.questionText.trim(),
  //         };
  //       }
  //       return item;
  //     });
  //     const finalData: any = {
  //       assessmentCategoryId: categoryData?.assessmentCategories.find(
  //         (item: any) => {
  //           return item._id === data.assessmentCategory._id;
  //         }
  //       )?._id,
  //       name: data?.name.trim(),
  //       questions: ques.filter((item: any, index: number) => !item?._id),
  //       id,
  //       isActive: isActive,
  //     };

  //     if (isOrderChanged) {
  //       const questionsOrder: any = {};
  //       data.questions?.forEach((item: any, index: number) => {
  //         if (item._id) {
  //           questionsOrder[item._id] = index + 1;
  //         }
  //       });
  //       finalData.questionsOrder = questionsOrder;
  //     }
  //     updateAssessmentData(finalData);
  //     setIsOrderChanged(null);
  //   } else {
  //     // const finalData = {
  //     //   assessmentCategoryId: categoryData?.assessmentCategories.find(
  //     //     (item: any) => {
  //     //       return item._id === data.assessmentCategory._id;
  //     //     }
  //     //   )?._id,
  //     //   name: data?.name.trim(),
  //     //   questions: finalQuestionData,
  //     //   isActive: isActive,
  //     // };

  //     let body: any = {};
  //     body = {
  //       ...finalQuestionData
  //     }

  //     const finalData = {
  //       assessmentCategoryId: data?.assessmentCategory._id,
  //       name: data?.name.trim(),
  //       isActive: isActive,
  //       questions: body
  //     };

  //     console.log(finalData);

  //     addAssessment(finalData);
  //   }
  // };

  const onSubmit = (data: any) => {
    const isActive = data?.status === 'active';

    const questionsData = data?.questions.map((item: any, index: number) => {
      // const questionData: any = {
      //   [`questions[${index}][type]`]: item.type,
      //   [`questions[${index}][questionText]`]: item.questionText,
      //   [`questions[${index}][hint]`]: item.hint,
      //   [`questions[${index}][choices][0][choiceText]`]: item.choices[0]?.choiceText,
      //   [`questions[${index}][questionOrder]`]: index + 1,
      //   [`questions[${index}][choices][0][isRight]`]: item.choices[0]?.isRight,
      //   [`questions[${index}][choices][1][choiceText]`]: item.choices[1]?.choiceText,
      //   [`questions[${index}][choices][1][isRight]`]: item.choices[1]?.isRight,
      //   [`questions[${index}][fileType]`]: item.fileType,
      // };

      // if (item.file) {
      //   questionData[`questions[${index}][file]`] = item.file;
      // }

      const questionData: any = {
        [`questions[${index}][type]`]: item.type,
        [`questions[${index}][questionText]`]: item.questionText,
        [`questions[${index}][hint]`]: item.hint,
        [`questions[${index}][questionOrder]`]: index + 1,
        [`questions[${index}][fileType]`]: item.fileType,
      };

      if (item.file) {
        questionData[`questions[${index}][file]`] = item.file;
      }

      // Iterate over choices dynamically
      if (item.choices && item.choices.length > 0) {
        item.choices.forEach((choice: any, choiceIndex: number) => {
          questionData[
            `questions[${index}][choices][${choiceIndex}][choiceText]`
          ] = choice.choiceText;
          questionData[
            `questions[${index}][choices][${choiceIndex}][isRight]`
          ] = choice.isRight;
        });
      }

      return questionData;
    });

    if (id) {
      const existingItemsWithId = data.questions?.filter(
        (item: any) => item._id
      ).length;
      const ques = data.questions?.map((item: any, index: number) => {
        if (!item?._id) {
          const indexs = index - existingItemsWithId;
          // return {
          //   // ...item,
          //   // questionOrder: index + 1,
          //   // questionText: item.questionText.trim(),
          //   [`questions[${indexs}][type]`]: item.type,
          //   [`questions[${indexs}][questionText]`]: item.questionText,
          //   [`questions[${indexs}][hint]`]: item.hint,
          //   [`questions[${indexs}][choices][0][choiceText]`]: item.choices[0]?.choiceText,
          //   [`questions[${indexs}][questionOrder]`]: index + 1,
          //   [`questions[${indexs}][choices][0][isRight]`]: item.choices[0]?.isRight,
          //   [`questions[${indexs}][choices][1][choiceText]`]: item.choices[1]?.choiceText,
          //   [`questions[${indexs}][choices][1][isRight]`]: item.choices[1]?.isRight,
          //   [`questions[${indexs}][fileType]`]: item.fileType,
          // };

          if (item.fileType === 'image' && !item.file) {
            toast.error('Image is required');
            return null;
          }

          if (item.fileType === 'pdf' && !item.file) {
            toast.error('PDF is required');
            return null;
          }

          const questionUpdateData: any = {
            [`questions[${indexs}][type]`]: item.type,
            [`questions[${indexs}][questionText]`]: item.questionText,
            [`questions[${indexs}][hint]`]: item.hint,
            [`questions[${indexs}][questionOrder]`]: index + 1,
            [`questions[${indexs}][fileType]`]: item.fileType,
          };

          // Handle choices dynamically
          if (item.choices && item.choices.length > 0) {
            item.choices.forEach((choice: any, choiceIndex: number) => {
              questionUpdateData[
                `questions[${indexs}][choices][${choiceIndex}][choiceText]`
              ] = choice.choiceText;
              questionUpdateData[
                `questions[${indexs}][choices][${choiceIndex}][isRight]`
              ] = choice.isRight;
            });
          }

          // Handle image
          if (item.file) {
            questionUpdateData[`questions[${indexs}][file]`] = item.file;
          }

          return questionUpdateData;
        }
      });

      const filteredQuestions = ques?.filter((item: any) => item !== undefined);

      if (isOrderChanged) {
        const questionsOrder: any = {};
        data.questions?.forEach((item: any, index: number) => {
          if (item._id) {
            questionsOrder[item._id] = index + 1;
          }
        });

        // Include the questionsOrder in the filteredQuestions object
        filteredQuestions.questionsOrder = questionsOrder;
      }

      const bodyData = {
        filteredQuestions,
      };

      const finalData = {
        assessmentCategoryId: data?.assessmentCategory._id,
        name: data?.name.trim(),
        isActive: isActive,
        id,
        ...Object.assign({}, ...filteredQuestions),
      };

      if (id && isOrderChanged) {
        updateAssessmentOrderData({
          id,
          ...bodyData.filteredQuestions.questionsOrder,
        });
      } else {
        updateAssessmentData(finalData);
      }

      setIsOrderChanged(null);
    } else {
      const finalData = {
        assessmentCategoryId: data?.assessmentCategory._id,
        name: data?.name.trim(),
        isActive: isActive,
        ...Object.assign({}, ...questionsData),
      };

      addAssessment(finalData);
    }
  };

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      notify(
        `Assessment ${isAddSuccess ? 'added' : 'updated'} successfully`,
        'success',
        1200
      );
      reset();
      navigate(-1);
    }
  }, [isAddSuccess, isUpdateSuccess, navigate, reset]);

  useEffect(() => {
    if (isOrderUpdateSuccess) {
      notify('Assessment updated successfully', 'success', 1200);
      reset();
      navigate(-1);
    }
  }, [isOrderUpdateSuccess, navigate, reset]);

  return isAssessmentLoading || isAssessmentFetching || isSetLoading ? (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Loader />
    </Box>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" mb={3}>
        {id ? textConst.edit : textConst.add} {textConst.assessment}
      </Typography>
      <Card sx={{ p: 3, minHeight: '5%', mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <HookAutoComplete
              name="assessmentCategory"
              label={'Assessment Category'}
              control={control}
              multiple={false}
              options={categoryData?.assessmentCategories || []}
              getOptionLabel="name"
              loading={isLoading || isFetching}
              onChange={(e: any) => {
                setSearchVal(e.target.value);
              }}
              // onSearchValueChange={(value: any) => setSearchVal(value)}
              inputProps={{ autoComplete: 'off' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HookTextField
              control={control}
              name="name"
              label="Title"
              inputProps={{ autoComplete: 'off' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Stack
            alignItems={{ xs: 'center', md: 'start', lg: 'center' }}
            justifyContent="flex-start"
            flexDirection={{ xs: 'row', md: 'column', lg: 'row' }}
          >
            <Typography
              marginRight={'25px'}
              color={(theme) => theme.palette.grey[500]}
            >
              {textConst.status}:
            </Typography>
            <HookRadioGroupField
              control={control}
              defaultValue={'active'}
              row={true}
              name="status"
            >
              <FormControlLabel
                value="active"
                control={<Radio />}
                label={
                  <Typography color={(theme) => theme.palette.grey[500]}>
                    {textConst.active}
                  </Typography>
                }
              />
              <FormControlLabel
                value="inactive"
                control={<Radio />}
                label={
                  <Typography color={(theme) => theme.palette.grey[500]}>
                    {textConst.inactive}
                  </Typography>
                }
              />
            </HookRadioGroupField>
          </Stack>
        </Grid>
      </Card>
      <Box py={2} my={1}>
        <LearningQuestions
          control={control}
          errors={errors}
          setValue={setValue}
          getValue={getValues}
          watch={watch}
          setIsOrderChanged={setIsOrderChanged}
          saveButtonClicked={saveButtonClicked}
        />
      </Box>
      <Stack direction={'row'} gap={2} justifyContent={'start'}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            width: { xs: '100%', sm: 'auto' },
            p: '3px 10px',
          }}
        >
          {textConst.cancel}
        </Button>

        <LoadingButton
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          variant="contained"
          type="submit"
          onClick={async () => {
            setSaveButtonClicked(true);
            setTimeout(() => {
              setSaveButtonClicked(null);
            }, 200);
            const validate = await trigger([
              'name',
              'assessmentCategory',
              'questions',
            ]);

            if (!validate) {
              notify('Please filled the details', 'error', 1200);
            }
          }}
          disabled={isAddLoading || isUpdateLoading || isOrderUpdateLoading}
          isLoading={isAddLoading || isUpdateLoading || isOrderUpdateLoading}
        >
          {textConst.save}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default AddEditAssessment;

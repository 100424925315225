import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useGetAllRolesQuery } from '../store/apis/role';
import { useGetUserQuery } from '../store/apis/user';
import { setRoles } from '../store/role';
import { setUserInfo } from '../store/user';
import {
  deleteAllCookies,
  getCookie,
  jwtDecode,
  notify,
  setCookie,
} from '../utils/commonFunctions';
import {
  setIsAssessmentOpened,
  slideDuration,
  updateInterval,
  updateLastWatchedVideoTime,
} from '../utils/constant';

interface AuthContextProps {
  authenticated: string | null | undefined | boolean;
  setAuth: ({ token, refToken }: { token: string; refToken: string }) => void;
  removeAuth: () => void;
}

const token = getCookie('token') ? JSON.parse(getCookie('token')) : false;
const refToken = getCookie('refresh')
  ? JSON.parse(getCookie('refresh'))
  : false;
const AuthContext = React.createContext<AuthContextProps>({
  authenticated: null,
  setAuth: function () {},
  removeAuth: function () {},
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth, setAuth] = useState<{ token: string; refToken: string }>({
    token: token,
    refToken: refToken,
  });
  const refTokenData = auth.refToken && jwtDecode(auth.refToken).payload;
  const { data: userData, isSuccess: isUserSuccess } = useGetUserQuery(
    refTokenData?.aud,
    {
      skip: !refTokenData?.aud || auth.token,
    }
  );

  const {
    data: roleList,
    isSuccess: isRoleSuccess,
    isError,
    error,
  } = useGetAllRolesQuery(
    {},
    {
      skip: !auth.token || !auth.refToken,
    }
  );

  useEffect(() => {
    if (isError) {
      notify(error?.data?.message, 'error', 1200);
    }
  }, [error?.data?.message, isError]);

  const removeAuth = () => {
    dispatch({ type: 'logout' });
    deleteAllCookies();
    setAuth({ token: '', refToken: '' });
    localStorage.removeItem('openIndex');
    navigate('/log-in');
    // window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_URL}`);
    updateInterval(0);
    updateLastWatchedVideoTime(0);
    setIsAssessmentOpened([]);
    slideDuration.slide = 0;
    slideDuration.duration = 0;
  };

  const addAuth = ({
    token,
    refToken,
  }: {
    token: string;
    refToken: string;
  }) => {
    setCookie('token', token);
    setCookie('refresh', refToken);
    setAuth({ token, refToken });
  };

  const value: AuthContextProps = {
    authenticated: !!auth.token || !!auth.refToken,
    setAuth: addAuth,
    removeAuth,
  };

  useEffect(() => {
    if (isUserSuccess) {
      dispatch(setUserInfo(userData));
    }
  }, [dispatch, isUserSuccess, userData]);

  useEffect(() => {
    if (isRoleSuccess) {
      dispatch(setRoles(roleList));
    }
  }, [dispatch, isRoleSuccess, roleList]);

  useEffect(() => {
    if (auth.token) {
      dispatch(setUserInfo(jwtDecode(auth.token).payload));
    }
  }, [auth?.token, dispatch]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthRedirect({
  children,
  authenticatedRoute = true,
}: {
  children: React.ReactNode;
  authenticatedRoute?: boolean;
}) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authenticated && authenticatedRoute) {
    // window.location.assign(`${process.env.REACT_APP_SERVER_REDIRECT_URL}`);
    return <Navigate to="/log-in" state={{ from: location }} />;
  } else if (auth.authenticated && !authenticatedRoute) {
    return <Navigate to="/home" state={{ from: location }} />;
  }

  return <>{children}</>;
}

AuthRedirect.propTypes = {
  children: PropTypes.element.isRequired,
  authenticatedRoute: PropTypes.bool,
};

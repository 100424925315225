// import React from 'react';

import { Typography } from '@mui/material';

// import Box from '@mui/material/Box';

// import iCorpImg from './../assets/images/i-corp.png';

const LogoHeader = () => {
  return (
    <>
      {/* <Box textAlign="center" mb={'48.5px'}>
        <img src={iCorpImg} alt="i-corp" height={'118.5px'} width={'192px'} />
      </Box> */}
      <Typography variant="h4" textAlign="center">
        Learning
        <span style={{ fontSize: '34px', fontWeight: 600 }}>
          {' '}
          Management System
        </span>
      </Typography>
    </>
  );
};

export default LogoHeader;

import React, { useEffect, useMemo, useState } from 'react';

// import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';

import LoadingButton from './LoadingButton';
import { useUpdateUserSummaryMutation } from '../store/apis/user';
import { userSelector } from '../store/user';
import { notify } from '../utils/commonFunctions';
import textConst from '../utils/textConst';

interface HookTextAreaProps {
  rows?: number;
  selectedProfile: any;
  userSummaryData: any;
  selectedEditIdRecord: any;
  selectedEditSummaryIdRecord: any;
  handleClose: any;
  [key: string]: any;
}

const HookTextEditSummary: React.FC<HookTextAreaProps> = ({
  rows,
  selectedProfile,
  userSummaryData,
  selectedEditIdRecord,
  selectedEditSummaryIdRecord,
  handleClose,
  ...rest
}) => {
  const [
    updateUserPostSummary,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateUserSummaryMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      notify('Summary updated successfully', 'success', 1200);
      handleClose();
      setEditMode(false);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      notify(updateError?.data?.message, 'error', 1200);
    }
  }, [isUpdateError, updateError]);

  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      summary: '',
    },
  });

  useEffect(() => {
    setValue('summary', userSummaryData.substring(0, 1000));
  }, [userSummaryData, setValue]);

  const summary = watch('summary');
  const user = useSelector(userSelector);

  const [isEditMode, setEditMode] = useState(true);
  const remainingCount = useMemo(
    () => 1000 - (summary?.length || 0),
    [summary?.length]
  );

  const onSubmit = (data: any) => {
    const body = {
      summary: data?.summary,
    };

    updateUserPostSummary({
      id: selectedEditIdRecord,
      summaryId: selectedEditSummaryIdRecord,
      ...body,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box py={2}>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey[500],
            borderRadius: '20px 20px 0 0',
            minHeight: '50px',
          }}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          px={2}
          py={1}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: 'white',
            }}
          >
            Summary
          </Typography>
        </Box>
        <Controller
          name="summary"
          control={control}
          render={({ field }: any) => (
            <TextField
              {...field}
              fullWidth
              multiline
              placeholder={
                user?.role !== 'Admin' && !summary?.length
                  ? '-'
                  : selectedProfile?.role?.name !== 'Admin'
                  ? 'Please enter a short summary'
                  : 'Please enter some information about yourself.'
              }
              rows={rows ?? undefined}
              disabled={!isEditMode}
              inputProps={{ maxLength: 1000 }}
              {...rest}
              sx={{
                '& .MuiInputBase-root': {
                  'borderRadius': '0 0 20px 20px',
                  'boxShadow': '0px 4px 10px 0px #0000001A',
                  '& fieldset': {
                    borderColor: 'transparent',
                    borderWidth: 0,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                    borderWidth: 0,
                  },
                },
                '& .MuiOutlinedInput-input.Mui-disabled': {
                  WebkitTextFillColor: '#000',
                },
              }}
            />
          )}
        />
      </Box>
      {/* {isEditMode && isAdminRole ? ( */}
      <Box
        display={'flex'}
        sx={{
          flexDirection: { xs: 'column', sm: 'row', md: 'column', lg: 'row' },
        }}
        justifyContent={'space-between'}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: (theme) => theme.palette.grey[500] }}
        >
          {remainingCount} Character{remainingCount > 0 ? '(s)' : ''} Remaining
        </Typography>

        <Box
          display={'flex'}
          sx={{
            my: { xs: 2, md: 0 },
            justifyContent: { xs: 'start', md: 'end' },
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setEditMode(false);
              handleClose();
            }}
            sx={{
              width: { xs: '100%', sm: 'auto' },
              p: '3px 10px',
            }}
          >
            {textConst.cancel}
          </Button>
          <LoadingButton
            isLoading={isUpdateLoading}
            type="submit"
            variant="contained"
            sx={{
              py: '3px',
              ml: 2,
            }}
          >
            {textConst.save}
          </LoadingButton>
        </Box>
      </Box>
      {/* ) : null} */}
    </form>
  );
};

export default HookTextEditSummary;

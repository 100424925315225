import React, { useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import Loader from './Loader';

interface CommonTableProps {
  rows: any[];
  columns: any[];
  page: number;
  rowsPerPage: number;
  handleClick?: (e: React.MouseEvent<HTMLTableRowElement>, row: any) => void;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  isPagination?: boolean;
  totalCount: number;
  isLoading?: boolean;
  sortMethod?: (column: any) => void;
  sortColumn?: any;
  sortOrder?: any;
  [key: string]: any;
  isSearching?: boolean;
  searchVal: any;
}

const CommonTableCourseSetting: React.FC<CommonTableProps> = ({
  // users,
  rows = [],
  columns,
  page,
  rowsPerPage,
  handleClick,
  onPageChange,
  onRowsPerPageChange,
  isPagination = true,
  totalCount,
  isLoading = false,
  isSearching = false,
  sortMethod,
  sortColumn,
  sortOrder,
  searchVal,
  ...rest
}) => {
  // const sortedRows = rows;

  // const sortedRows = useMemo(() => {
  //   if (!sortColumn) return rows;

  //   const orderMultiplier = sortOrder === 'asc' ? 1 : -1;

  //   return [...rows].sort((a, b) => {
  //     const aValue = a[sortColumn];
  //     const bValue = b[sortColumn];

  //     if (aValue < bValue) return -1 * orderMultiplier;
  //     if (aValue > bValue) return 1 * orderMultiplier;
  //     return 0;
  //   });
  // }, [rows, sortColumn, sortOrder]);

  const filteredRows = useMemo(() => {
    if (!searchVal) return rows;

    const lowerCaseSearchVal = searchVal.toLowerCase();

    return rows.filter((row) => {
      if (row?.courseId) {
        const courseName = `${row?.courseId?.name}`;
        const lowerCaseUserName = courseName.toLowerCase();
        return lowerCaseUserName.includes(lowerCaseSearchVal);
      }

      return false;
    });
  }, [rows, searchVal]);

  const isMobileScreen = useMediaQuery('(max-width:500px)');

  const mobileColumns = columns.filter(
    (column: any) =>
      column.id === 'name' ||
      column.id === 'percentage' ||
      column.id === 'action'
  );

  return (
    <Paper sx={{ borderRadius: '16px', position: 'relative' }} elevation={1}>
      {isSearching ? (
        <Box
          height={'100%'}
          width={'100%'}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            borderRadius: '16px',
            zIndex: 1,
          }}
        >
          <Loader />
        </Box>
      ) : null}
      <TableContainer
        sx={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
      >
        <Box sx={{ overflowX: isMobileScreen ? 'scroll' : 'inherit' }}>
          <Table
            sx={{
              minWidth: 500,
            }}
          >
            <TableHead
              sx={{
                backgroundColor: (theme) => theme.palette.grey[500],
              }}
            >
              <TableRow>
                {isMobileScreen
                  ? mobileColumns.map((column: any, columnIndex: any) => (
                      <TableCell
                        sx={{
                          width: '100%',
                          backgroundColor: (theme) => theme.palette.grey[500],
                        }}
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          padding: '12px 16px',
                          paddingRight: column.paddingRight,
                          position:
                            columnIndex === mobileColumns.length - 1 &&
                            column.id === 'action'
                              ? 'sticky'
                              : 'inherit',
                          right:
                            columnIndex === mobileColumns.length - 1
                              ? 0
                              : 'auto',
                        }}
                      >
                        <TableSortLabel
                          active={sortColumn === column.field}
                          direction={
                            sortColumn === column.field ? sortOrder : 'asc'
                          }
                          onClick={
                            column.sortable
                              ? (e) => {
                                  sortMethod?.(column);
                                }
                              : undefined
                          }
                          hideSortIcon={!column.sortable}
                          sx={{
                            'cursor':
                              column.sortable === true ? 'pointer' : 'default',
                            'color': 'white',
                            '&.Mui-active': {
                              color: 'white',
                            },
                            '&:hover': {
                              color: 'white',
                            },
                            '&.MuiTableSortLabel-root': {
                              color: 'white',
                            },
                            '& .MuiTableSortLabel-icon': {
                              'color': 'white',
                              '&.MuiTableSortLabel-iconDirectionAsc': {
                                color: 'white',
                              },
                              '&.MuiTableSortLabel-iconDirectionDesc': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                          {column?.label}
                          {sortColumn === column ? (
                            <span style={visuallyHidden}>
                              {sortOrder === 'asc'
                                ? 'sorted ascending'
                                : 'sorted descending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                        {sortColumn === column ? (
                          <span style={visuallyHidden}>
                            {sortOrder === 'asc'
                              ? 'sorted ascending'
                              : 'sorted descending'}
                          </span>
                        ) : null}
                      </TableCell>
                    ))
                  : columns.map((column: any, columnIndex: any) => (
                      <TableCell
                        sx={{
                          // width: '50%',
                          backgroundColor: (theme) => theme.palette.grey[500],
                        }}
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          padding: '12px 16px',
                          paddingRight: column.paddingRight,
                          position:
                            columnIndex === columns.length - 1 &&
                            column.id === 'action'
                              ? 'sticky'
                              : 'inherit',
                          right:
                            columnIndex === columns.length - 1 ? 0 : 'auto',
                        }}
                      >
                        <TableSortLabel
                          active={sortColumn === column.field}
                          direction={
                            sortColumn === column.field ? sortOrder : 'asc'
                          }
                          onClick={
                            column.sortable
                              ? (e) => {
                                  sortMethod?.(column);
                                }
                              : undefined
                          }
                          hideSortIcon={!column.sortable}
                          sx={{
                            'cursor':
                              column.sortable === true ? 'pointer' : 'default',
                            'color': 'white',
                            '&.Mui-active': {
                              color: 'white',
                            },
                            '&:hover': {
                              color: 'white',
                            },
                            '&.MuiTableSortLabel-root': {
                              color: 'white',
                            },
                            '& .MuiTableSortLabel-icon': {
                              'color': 'white',
                              '&.MuiTableSortLabel-iconDirectionAsc': {
                                color: 'white',
                              },
                              '&.MuiTableSortLabel-iconDirectionDesc': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                          {column?.label}
                          {sortColumn === column ? (
                            <span style={visuallyHidden}>
                              {sortOrder === 'asc'
                                ? 'sorted ascending'
                                : 'sorted descending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                        {sortColumn === column ? (
                          <span style={visuallyHidden}>
                            {sortOrder === 'asc'
                              ? 'sorted ascending'
                              : 'sorted descending'}
                          </span>
                        ) : null}
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* ... (rest of the code) */}
              {isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={
                      isMobileScreen ? mobileColumns.length : columns.length
                    }
                    align="center"
                    sx={{ borderBottom: 0 }}
                  >
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : filteredRows?.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={
                      isMobileScreen ? mobileColumns.length : columns.length
                    }
                    align="center"
                    sx={{ borderBottom: 0 }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                filteredRows?.map((row: any) => (
                  <TableRow
                    key={row?._id || row.id}
                    hover
                    onClick={(e) => handleClick?.(e, row)}
                    {...rest}
                    sx={{
                      'borderRadius': '16px',
                      'marginBottom': '16px',
                      '&:last-child td': {
                        borderBottom: 0,
                      },
                      'cursor': handleClick ? 'pointer' : 'default',
                    }}
                  >
                    {isMobileScreen
                      ? mobileColumns.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              py: '10px',
                              px: 2,
                              position:
                                column.id === 'action' ? 'sticky' : 'inherit',
                              right: column.id === 'action' ? 0 : 'auto',
                              backgroundColor: 'white',
                              borderRadius: '16px',
                            }}
                          >
                            {column.format
                              ? column.format(row)
                              : row[column.id]}
                          </TableCell>
                        ))
                      : columns.map((column: any, columnIndex: any) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              py: '10px',
                              px: 2,
                              position:
                                columnIndex === columns.length - 1 &&
                                column.id === 'action'
                                  ? 'sticky'
                                  : 'inherit',
                              right:
                                columnIndex === columns.length - 1 ? 0 : 'auto',
                              backgroundColor: 'white',
                              borderRadius: '16px',
                            }}
                          >
                            {column.format
                              ? column.format(row)
                              : row[column.id]}
                          </TableCell>
                        ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      {!isLoading && rows?.length !== 0 && isPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Paper>
  );
};

export default CommonTableCourseSetting;

import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MovingIcon from '@mui/icons-material/Moving';
import OutboxIcon from '@mui/icons-material/Outbox';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Avatar, ListItemAvatar, Tooltip, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  BlobProvider,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import placeHolderImage from './../../assets/images/placeholder.jpg';
import {
  capitalizeFirstLetter,
  getFullName,
  notify,
} from './../../utils/commonFunctions';
import {
  useGetAllCoursesQuery,
  useGetCourseFeedbackQuery,
  useLazySendCourseRemainderQuery,
} from '../../store/apis/courses';
import {
  useDeleteUserSummaryMutation,
  useGetUserQuery,
  useGetUserSummaryQuery,
} from '../../store/apis/user';
import { userSelector } from '../../store/user';
import { commonColors } from '../../theme-loader/theme/color';
import { chartData } from '../../utils/constant';
import textConst from '../../utils/textConst';
import AlertDialog from '../AlertDialog';
import AlertEditSummaryDialog from '../AlertEditSummaryDialog';
import AlertReminderDialog from '../AlertReminderDialog';
import AlertShowSummaryDialog from '../AlertShowSummaryDialog';
import CommonTableLearning from '../CommonTableLearning';
import CommonTableSummary from '../CommonTableSummary';
import CustomChip from '../CustomChip';
import CustomTabs from '../CustomTabs';
import { GenerateCertificatePdf } from '../generate-pdf/GenerateCertificatePdf';
import { GenerateProfilePdf } from '../generate-pdf/GenerateProfilePdf';
import { GenerateUserProfile } from '../generate-pdf/GenerateUserProfile';
import HookTextSummary from '../HookTextSummary';
import Loader from '../Loader';

interface ProfileInfoModalProps {
  isInfoModal?: boolean;
  onModalClose?: any;
  selectedProfile: any;
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
});

const ProfileContent: React.FC<ProfileInfoModalProps> = (props) => {
  const { isInfoModal, onModalClose, selectedProfile } = props;

  const isMobileScreen = useMediaQuery('(max-width:500px)');

  const users = useSelector(userSelector);

  const param = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const isLarge = useMediaQuery('(max-width:1550px)');
  const isMedium = useMediaQuery('(max-width:1360px)');
  const isExtraSmall = useMediaQuery('(max-width:600px)');
  const [listDivDetail, setListDivDetail] = useState<DOMRect | undefined>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<any>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const [openDialog, setOpenDialog] = useState(false);
  const [openShowDialog, setOpenShowDialog] = useState(false);
  const [disableCourseRemainder, setDisableCourseRemainder] = useState<any>([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedIdRecord, setSelectedIdRecord] = useState<any>(null);
  const [selectedEditRecord, setSelectedEditRecord] = useState<any>(null);
  const [selectedEditSummaryIdRecord, setSelectedEditSummaryIdRecord] =
    useState<any>(null);
  const [selectedEditIdRecord, setSelectedEditIdRecord] = useState<any>(null);
  const [courseId, setCourseId] = useState<any>(null);
  const [courseFeedbackId, setCourseFeedbackId] = useState<any>(null);

  const [pageSummary, setPageSummary] = React.useState(0);
  const [rowsPerPageSummary, setRowsPerPageSummary] = React.useState(5);

  // Event handler for page change
  const handlePageChange = (event: any, newPage: any) => {
    setPageSummary(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPageSummary(newRowsPerPage);
    setPageSummary(0);
  };

  const { data: courseFeedbackData } =
    useGetCourseFeedbackQuery(courseFeedbackId);

  const user = useSelector(userSelector);
  const id = selectedProfile?.user?._id || selectedProfile?._id;

  const [
    deleteUserPost,
    { isSuccess: isDeleteSuccess, isError: isDeleteError, error: deleteError },
  ] = useDeleteUserSummaryMutation();

  const handleClickOpen = (value: any) => {
    setOpenDeleteDialog(true);
    setSelectedRecord(value);
  };

  const handleClickShowOpen = (value: any) => {
    setOpenShowDialog(true);
    setSelectedIdRecord(value?.summary);
  };

  const handleClickEditOpen = (value: any) => {
    setOpenEditDialog(true);
    setSelectedEditIdRecord(value?.userId);
    setSelectedEditSummaryIdRecord(value?._id);
    setSelectedEditRecord(value?.summary);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseShow = () => {
    setOpenShowDialog(false);
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleReminderClose = () => {
    setOpenDialog(false);
  };

  const handleBoxClick = (row: any) => {
    if (param?.managerId && param?.userId) {
      navigate(
        `/users/list/manager/${param?.managerId}/user/${param?.userId}/course/${row?.course?._id}`
      );
    } else if (param?.userId) {
      if (isMyTeamPage) {
        navigate(`/my-team/user/${param?.userId}/course/${row?.course?._id}`);
      } else {
        navigate(
          `/users/list/user/${param?.userId}/course/${row?.course?._id}`
        );
      }
    } else {
      navigate(`/learning/course/${row?.course?._id}`);
    }
  };

  const columns: any[] = [
    {
      id: 'learning',
      label: 'Learning',
      minWidth: 150,
      sortable: true,
      field: 'course.name',
      format: (row: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleBoxClick(row)}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.grey[500],
                mr: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {row?.course?.name}
            </Typography>
            {row?.completedAt && row?.status === 'Completed' ? (
              <BlobProvider
                document={
                  <GenerateCertificatePdf
                    userName={`${
                      selectedProfile?.user?.firstName ||
                      selectedProfile?.firstName
                    } ${
                      selectedProfile?.user?.lastName ||
                      selectedProfile?.lastName
                    }`}
                    courseName={row?.course?.name}
                    courseCompletionDate={row?.completedAt}
                  />
                }
              >
                {({ url }) => (
                  <a
                    href={url || ''}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <WorkspacePremiumIcon color="primary" />
                  </a>
                )}
              </BlobProvider>
            ) : null}
          </Box>
        );
      },
    },
    {
      id: 'certificate',
      label: 'Download Certificate',
      minWidth: 130,
      sortable: true,
      field: 'download.ceriticate',
      format: (row: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleBoxClick(row)}
          >
            {row?.completedAt && row?.status === 'Completed' ? (
              <BlobProvider
                document={
                  <GenerateCertificatePdf
                    userName={`${
                      selectedProfile?.user?.firstName ||
                      selectedProfile?.firstName
                    } ${
                      selectedProfile?.user?.lastName ||
                      selectedProfile?.lastName
                    }`}
                    courseName={row?.course?.name}
                    courseCompletionDate={row?.completedAt}
                  />
                }
              >
                {({ url }) => (
                  <a
                    href={url || ''}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <Button variant="contained" color="secondary" type="submit">
                      Download
                    </Button>
                  </a>
                )}
              </BlobProvider>
            ) : (
              '-'
            )}
          </Box>
        );
      },
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 130,
      sortable: true,
      field: 'status',
      format: (row: any) => {
        const data = chartData.find((item) => item.text === row.status);
        if (!data) {
          return null;
        }
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleBoxClick(row)}
          >
            <CustomChip
              label={row.status}
              color="primary"
              sx={{ background: data?.color }}
            />
          </Box>
        );
      },
    },
    {
      id: 'due',
      label: 'Due',
      field: 'dueAt',
      minWidth: 170,
      align: 'left',
      format: (row: any) =>
        row?.dueAt ? moment(row.dueAt).format('DD/MM/YYYY') : '-',
      sortable: true,
    },
    {
      id: 'overDue',
      label: 'Overdue',
      field: 'isOverdue',
      minWidth: 110,
      format: (row: any) => {
        return (
          <Typography
            variant="subtitle2"
            fontWeight={400}
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            {row?.isOverdue ? 'Yes' : 'No'}
          </Typography>
        );
      },
      sortable: true,
    },
    {
      id: 'completed',
      label: 'Completed',
      field: 'completedAt',
      minWidth: 170,
      align: 'left',
      sortable: true,
      format: (row: any) => {
        // const timeString = completedAtDate.toLocaleString('en-US', {
        //   hour: 'numeric',
        //   minute: 'numeric',
        //   hour12: true
        // });

        return (
          // row?.completedAt ? moment(row.completedAt).format('DD/MM/YYYY') + ' ' + timeString : '-'
          row?.completedAt ? moment(row.completedAt).format('DD/MM/YYYY') : '-'
        );
      },
    },
    // {
    //   flex: 0.06,
    //   minWidth: 100,
    //   sortable: false,
    //   id: 'action',
    //   label: 'Actions',
    //   field: 'actions',
    //   headerName: 'Actions',
    //   format: (row: any) => {
    //     const isDisableCourse = disableCourseRemainder.some(
    //       (item: any) => item.id === id
    //     );

    //     const {
    //       data: courseFeedbackData,
    //     } = useGetCourseFeedbackQuery(row?.course?._id);

    //     const filteredCourseFeedbackData = courseFeedbackData?.filter(
    //       (feedback: any) => feedback?.userId?._id === row?.user?._id
    //     );

    //     console.log(filteredCourseFeedbackData);

    //     return (
    //       <Box>
    //         {row.status !== 'Completed' &&
    //           <Tooltip title="Send course remainder" arrow>
    //             <OutboxIcon
    //               sx={{
    //                 cursor: 'pointer',
    //                 color: (theme) =>
    //                   isDisableCourse
    //                     ? theme.palette.grey[200]
    //                     : commonColors.purple,
    //                 visibility:
    //                   row?.role?.name !== 'Admin' ? 'visible' : 'hidden',
    //               }}
    //               onClick={() => {
    //                 setCourseId(row?.course._id)
    //                 if (!isDisableCourse) {
    //                   handleOpenDialog();
    //                 } else {
    //                   undefined
    //                 }
    //               }}
    //             />
    //           </Tooltip>}

    //         {(row.status === 'Completed' || row.status === 'Recompletion') &&
    //           <Tooltip title={textConst.feedback} arrow>
    //             <FeedbackIcon
    //               sx={{
    //                 color: '#5B50ED',
    //                 cursor: 'pointer',
    //                 ml: row.status !== 'Completed' ? '1.5rem' : 0,
    //               }}
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 e.stopPropagation();
    //                 navigate(`/learning/feedback/user/course/${row?.course?._id}/${row?.user?._id}`);
    //               }}
    //             />
    //           </Tooltip>
    //         }
    //       </Box>
    //     )
    //   },
    // },

    // {
    //   flex: 0.06,
    //   minWidth: 100,
    //   sortable: false,
    //   id: 'action',
    //   label: 'Actions',
    //   field: 'actions',
    //   headerName: 'Actions',
    //   format: (row: any) => {
    //     const isDisableCourse = disableCourseRemainder.some(
    //       (item: any) => item.id === id
    //     );

    //     setCourseFeedbackId(row?.course?._id)

    //     const filteredCourseFeedbackData = courseFeedbackData?.filter(
    //       (feedback: any) => feedback?.userId?._id === row?.user?._id
    //     );

    //     const isFeedbackAvailable = filteredCourseFeedbackData && filteredCourseFeedbackData?.length > 0;

    //     return (
    //       <Box>
    //         {row.status !== 'Completed' && user?.role !== 'User' &&
    //           <Tooltip title="Send course remainder" arrow>
    //             <OutboxIcon
    //               sx={{
    //                 cursor: 'pointer',
    //                 color: (theme) =>
    //                   isDisableCourse
    //                     ? theme.palette.grey[200]
    //                     : commonColors.purple,
    //                 visibility:
    //                   row?.role?.name !== 'Admin' ? 'visible' : 'hidden',
    //               }}
    //               onClick={() => {
    //                 setCourseId(row?.course._id)
    //                 if (!isDisableCourse) {
    //                   handleOpenDialog();
    //                 } else {
    //                   undefined
    //                 }
    //               }}
    //             />
    //           </Tooltip>}

    //         {(row.status === 'Completed' || row.status === 'Recompletion') &&
    //           isFeedbackAvailable &&
    //           <Tooltip title={textConst.feedback} arrow>
    //             <FeedbackIcon
    //               sx={{
    //                 color: '#5B50ED',
    //                 cursor: 'pointer',
    //                 ml: row.status !== 'Completed' ? '1.5rem' : 0,
    //               }}
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 e.stopPropagation();
    //                 navigate(`/learning/feedback/user/course/${row?.course?._id}/${row?.user?._id}`);
    //               }}
    //             />
    //           </Tooltip>
    //         }
    //       </Box>
    //     )
    //   },
    // },
  ];

  // Conditionally add the "Actions" column
  if (users?.role !== 'User') {
    columns.push({
      flex: 0.06,
      minWidth: 100,
      sortable: false,
      id: 'action',
      label: 'Actions',
      field: 'actions',
      headerName: 'Actions',
      format: (row: any) => {
        const isDisableCourse = disableCourseRemainder.some(
          (item: any) => item.id === id
        );

        setCourseFeedbackId(row?.course?._id);

        const filteredCourseFeedbackData = courseFeedbackData?.filter(
          (feedback: any) => feedback?.userId?._id === row?.user?._id
        );

        const isFeedbackAvailable =
          filteredCourseFeedbackData && filteredCourseFeedbackData?.length > 0;

        return (
          <Box>
            {row.status !== 'Completed' && (
              <Tooltip title="Send course reminder" arrow>
                <OutboxIcon
                  sx={{
                    cursor: 'pointer',
                    color: (theme) =>
                      isDisableCourse
                        ? theme.palette.grey[200]
                        : commonColors.purple,
                    visibility:
                      row?.role?.name !== 'Admin' ? 'visible' : 'hidden',
                  }}
                  onClick={() => {
                    setCourseId(row?.course._id);
                    if (!isDisableCourse) {
                      handleOpenDialog();
                    }
                  }}
                />
              </Tooltip>
            )}

            {(row.status === 'Completed' || row.status === 'Recompletion') &&
              isFeedbackAvailable && (
                <Tooltip title={textConst.feedback} arrow>
                  <FeedbackIcon
                    sx={{
                      color: '#5B50ED',
                      cursor: 'pointer',
                      ml: row.status !== 'Completed' ? '1.5rem' : 0,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(
                        `/learning/feedback/user/course/${row?.course?._id}/${row?.user?._id}`
                      );
                    }}
                  />
                </Tooltip>
              )}
          </Box>
        );
      },
    });
  }

  const columnsSummary: any[] = [
    {
      id: 'summary',
      label: 'Summary Name',
      minWidth: 100,
      sortable: true,
      field: 'summary.name',
      format: (row: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleClickShowOpen(row)}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.grey[500],
                mr: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {`${row?.summary}`
                ?.slice(0, 150)
                .concat(`${row?.summary}`.length > 100 ? '...' : '') + ' '}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   id: 'createdAt',
    //   label: 'Created At',
    //   field: 'createdAt',
    //   minWidth: 170,
    //   align: 'left',
    //   sortable: true,
    //   format: (row: any) => {
    //     const completedAtString = row.createdAt;
    //     const completedAtDate = new Date(completedAtString);

    //     const timeString = completedAtDate.toLocaleString('en-US', {
    //       hour: 'numeric',
    //       minute: 'numeric',
    //       hour12: true
    //     });

    //     return (
    //       row?.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') + ' ' + timeString : '-'
    //     )
    //   }
    // },
    // {
    //   id: 'updatedAt',
    //   label: 'Updated At',
    //   field: 'updatedAt',
    //   minWidth: 170,
    //   align: 'left',
    //   sortable: true,
    //   format: (row: any) => {
    //     const completedAtString = row.updatedAt;
    //     const completedAtDate = new Date(completedAtString);

    //     const timeString = completedAtDate.toLocaleString('en-US', {
    //       hour: 'numeric',
    //       minute: 'numeric',
    //       hour12: true
    //     });

    //     return (
    //       row?.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY') + ' ' + timeString : '-'
    //     )
    //   }
    // },
    {
      flex: 0.06,
      minWidth: 100,
      sortable: false,
      id: 'action',
      label: 'Actions',
      field: 'actions',
      headerName: 'Actions',
      format: (row: any) => {
        return (
          <>
            <Tooltip title="Edit" arrow>
              <ModeEditIcon
                sx={{
                  cursor: 'pointer',
                  color: (theme) =>
                    // user?._id === id
                    //   ? theme.palette.grey[200]
                    // :
                    theme.palette.grey[500],
                }}
                onClick={() => {
                  handleClickEditOpen(row);
                }}
              />
            </Tooltip>

            <Tooltip title="Delete" arrow>
              <DeleteIcon
                sx={{
                  cursor: 'pointer',
                  ml: 1.5,
                  color: (theme) =>
                    // user?._id === id
                    //   ? theme.palette.grey[200]
                    // :
                    theme.palette.primary.dark,
                }}
                onClick={() => {
                  handleClickOpen(row);
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const {
    data: userData,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetUserQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: userSummaryData,
    refetch,
    isLoading: isUserSummaryLoading,
    isFetching: isUserSummaryFetching,
  } = useGetUserSummaryQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const startIndex = pageSummary * rowsPerPageSummary;
  const endIndex = startIndex + rowsPerPageSummary;
  const displayedData = userSummaryData?.slice(startIndex, endIndex) || [];
  const filteredData =
    displayedData?.filter((row: any) => {
      return (
        row?.summary !== 'undefined' &&
        row?.summary !== null &&
        row?.summary !== undefined
      );
    }) || [];

  const {
    data: courseData,
    isLoading: isCourseLoading,
    isError: isCourseError,
    error: courseError,
    isFetching: isCourseFetching,
  } = useGetAllCoursesQuery(
    {
      userId: id,
      page: page + 1,
      limit: rowsPerPage,
      sortField: sortField ?? 'createdAt',
      sortOrder: sortField ? (sortOrder === 'asc' ? 1 : -1) : '',
    },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: allCourseData } = useGetAllCoursesQuery(
    {
      userId: id,
      page: page + 1,
      sortField: sortField ?? 'createdAt',
      sortOrder: sortField ? (sortOrder === 'asc' ? 1 : -1) : '',
    },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    sendCourseRemainder,
    {
      isLoading: isSendCourseRemainderLoading,
      isError: isSendCourseRemainderError,
      error: sendCourseRemainderError,
      isSuccess: isSendCourseRemainderSuccess,
      isFetching: isSendCourseRemainderFetching,
    },
  ] = useLazySendCourseRemainderQuery();

  const handleDelete = (value: any) => {
    setOpenDeleteDialog(false);
    deleteUserPost({
      id: value?.userId,
      summaryId: value?._id,
    });
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      notify('Summary deleted successfully', 'success', 1200);
      refetch();
    }
  }, [isDeleteSuccess, refetch]);

  useEffect(() => {
    if (isDeleteError) {
      notify(deleteError?.data?.message, 'error');
    }
  }, [deleteError, isDeleteError]);

  useEffect(() => {
    if (disableCourseRemainder.length > 0) {
      const timer = setInterval(() => {
        const currentTime = Date.now();

        const updatedIds = disableCourseRemainder.filter(
          (item: any) => item.timestamp > currentTime
        );
        setDisableCourseRemainder(updatedIds);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [disableCourseRemainder]);

  useEffect(() => {
    if (isCourseError) {
      notify(courseError?.data?.message, 'error', 1200);
    }
  }, [courseError?.data?.message, isCourseError]);

  useEffect(() => {
    if (isSendCourseRemainderError) {
      notify(sendCourseRemainderError?.data?.message, 'error', 1200);
    }
  }, [isSendCourseRemainderError, sendCourseRemainderError?.data?.message]);

  useEffect(() => {
    if (isSendCourseRemainderSuccess && !isSendCourseRemainderFetching) {
      notify('Email sent successfully', 'success', 1200);
    }
  }, [isSendCourseRemainderFetching, isSendCourseRemainderSuccess]);

  const handleSendRemiderMail = () => {
    sendCourseRemainder({
      id: id,
      courseId: courseId,
    });
    setOpenDialog(false);
  };

  const isManager =
    selectedProfile?.managers?.length || selectedProfile?.manager?.firstName;

  const isProfilePage = useMemo(
    () => location.pathname.includes('profile'),
    [location.pathname]
  );
  const isMyTeamPage = useMemo(
    () => location.pathname.includes('my-team'),
    [location.pathname]
  );

  useLayoutEffect(() => {
    const listDiv = document
      .getElementById('user-list')
      ?.getBoundingClientRect();
    setListDivDetail(listDiv);
  }, []);

  const isAdminRole = selectedProfile?.role?.name === 'Admin';
  const isCurrentUserAdmin = user?.role === 'Admin';

  // const isManagerRole = selectedProfile?.role?.name === 'Manager';
  // const isUserRole = selectedProfile?.role?.name === 'User';

  const handleAssignCourse = () => {};
  const handleDownloadProfile = () => {};

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const adminTabs = [
    {
      label: 'Summary',
      component: (
        <Box maxWidth={{ xs: '80vw', sm: '100vw' }}>
          <CommonTableSummary
            users={users}
            rows={filteredData || []}
            columns={columnsSummary}
            page={pageSummary}
            rowsPerPage={rowsPerPageSummary}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            totalCount={filteredData?.length || 0}
            isPagination={true}
            sortMethod={(column: any) => {
              setSortField(column?.field);
              setSortOrder((prevSortOrder) =>
                column?.field === sortField && prevSortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
              );
            }}
            sortColumn={sortField}
            sortOrder={sortOrder}
            isSearching={isUserSummaryFetching}
          />
        </Box>
      ),
    },
  ];

  const tabs = [
    {
      label: 'Activity',
      component: (
        <Box maxWidth={{ xs: '80vw', sm: '100vw' }}>
          <CommonTableLearning
            rows={courseData?.courses || []}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            // handleClick={(e, row) => {
            //   if (param?.managerId && param?.userId) {
            //     navigate(
            //       `/users/list/manager/${param?.managerId}/user/${param?.userId}/course/${row?.course?._id}`
            //     );
            //   } else if (param?.userId) {
            //     if (isMyTeamPage) {
            //       navigate(
            //         `/my-team/user/${param?.userId}/course/${row?.course?._id}`
            //       );
            //     } else {
            //       navigate(
            //         `/users/list/user/${param?.userId}/course/${row?.course?._id}`
            //       );
            //     }
            //   } else {
            //     navigate(`/learning/course/${row?.course?._id}`);
            //   }
            // }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            totalCount={courseData?.pagination?.totalCount}
            isPagination={true}
            sortMethod={(column: any) => {
              setSortField(column?.field);
              setSortOrder((prevSortOrder) =>
                column?.field === sortField && prevSortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
              );
            }}
            sortColumn={sortField}
            sortOrder={sortOrder}
            // isSearching={isCourseFetching || isSendCourseRemainderLoading}
            isSearching={
              isCourseFetching ||
              isSendCourseRemainderLoading ||
              isSendCourseRemainderFetching
            }
          />
        </Box>
      ),
    },
    // {
    //   label: 'Summary',
    //   component: (
    //     <Box maxWidth={{ xs: '80vw', sm: '100vw' }}>
    //       <CommonTableSummary
    //         users={users}
    //         rows={displayedData || []}
    //         columns={columnsSummary}
    //         page={pageSummary}
    //         rowsPerPage={rowsPerPageSummary}
    //         onPageChange={handlePageChange}
    //         onRowsPerPageChange={handleRowsPerPageChange}
    //         totalCount={userSummaryData?.length || 0}
    //         isPagination={true}
    //         sortMethod={(column: any) => {
    //           setSortField(column?.field);
    //           setSortOrder((prevSortOrder) =>
    //             column?.field === sortField && prevSortOrder === 'asc'
    //               ? 'desc'
    //               : 'asc'
    //           );
    //         }}
    //         sortColumn={sortField}
    //         sortOrder={sortOrder}
    //         isSearching={
    //           (isUserSummaryFetching)
    //         }
    //       />
    //     </Box>
    //   ),
    // },
  ];

  return (
    <Box
      sx={{
        'width': '100%',
        'backgroundColor': 'white',
        'padding': '20px',
        'borderRadius': '10px',
        'boxShadow': '0px 4px 10px 0px #0000001A',
        'height': `${
          isInfoModal
            ? 'auto'
            : listDivDetail?.height
            ? listDivDetail?.height + (isCurrentUserAdmin ? 110 : 2) + 'px'
            : '100%'
        }`,
        'overflowX': 'hidden',
        'overflowY':
          isUserLoading ||
          isCourseLoading ||
          isUserFetching ||
          isCourseFetching ||
          isUserSummaryLoading ||
          isUserSummaryFetching
            ? 'hidden'
            : 'auto',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#afafaf',
          borderRadius: '10px',
        },
      }}
    >
      {isUserLoading || isUserSummaryLoading || isCourseLoading ? (
        <Loader />
      ) : selectedProfile ? (
        <>
          <Grid container gap={1}>
            <Grid
              item
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              {/* <ListItemAvatar>
                {userData?.image ? (
                  <Box
                    sx={{ mr: 2, borderRadius: 1, overflow: 'hidden' }}
                    width="130px"
                    height="130px"
                  >
                    <img
                      src={userData?.image}
                      alt="profile_image"
                      width="130px"
                      height="130px"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = placeHolderImage;
                      }}
                    />
                  </Box>
                ) : (
                  <Avatar
                    alt={
                      selectedProfile?.firstName ||
                      selectedProfile?.user?.firstName
                    }
                    src={''}
                    sx={{
                      fontSize: 40,
                      width: { xs: '130px' },
                      height: { xs: '130px' },
                      mr: 2,
                      borderRadius: 1,
                    }}
                    variant={'rounded'}
                  />
                )}
              </ListItemAvatar> */}
              <ListItemAvatar>
                {userData?.image ? (
                  <Box
                    sx={{ mr: 2, borderRadius: 1, overflow: 'hidden' }}
                    width="130px"
                    height="130px"
                  >
                    <img
                      src={
                        userData?.image + `?timestamp=${new Date().getTime()}`
                      }
                      alt="profile_image"
                      width="130px"
                      height="130px"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = placeHolderImage;
                      }}
                    />
                  </Box>
                ) : (
                  <Avatar
                    alt={
                      selectedProfile?.firstName ||
                      selectedProfile?.user?.firstName
                    }
                    src={''}
                    sx={{
                      fontSize: 40,
                      width: { xs: '130px' },
                      height: { xs: '130px' },
                      mr: 2,
                      borderRadius: 1,
                    }}
                    variant={'rounded'}
                  />
                )}
              </ListItemAvatar>

              <Box
                sx={{
                  display: {
                    xs: isInfoModal ? 'flex' : 'none',
                    sm: 'none',
                    justifyContent: 'end',
                  },
                }}
                onClick={() => {
                  onModalClose?.();
                }}
              >
                <CloseIcon />
              </Box>
            </Grid>
            <Grid
              item
              xs="auto"
              sm={7.8}
              md="auto"
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              height="100%"
              style={{
                flexGrow: 1,
              }}
              sx={{
                mt: { xs: 2, sm: 0 },
              }}
            >
              <Typography variant="h5">
                {isProfilePage
                  ? capitalizeFirstLetter(selectedProfile?.firstName) +
                    ' ' +
                    capitalizeFirstLetter(selectedProfile?.lastName)
                  : capitalizeFirstLetter(
                      selectedProfile?.user?.firstName ||
                        selectedProfile?.firstName
                    ) +
                    ' ' +
                    capitalizeFirstLetter(
                      selectedProfile?.user?.lastName ||
                        selectedProfile?.lastName
                    )}
              </Typography>
              {!isAdminRole && (
                <Box>
                  {isManager && (
                    <Stack
                      sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                      gap={1}
                    >
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.grey[400],
                        }}
                      >
                        Manager
                      </Typography>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.grey[600],
                        }}
                      >
                        {getFullName(selectedProfile?.managers)?.toString() ??
                          capitalizeFirstLetter(
                            selectedProfile?.manager?.firstName
                          ) +
                            ' ' +
                            capitalizeFirstLetter(
                              selectedProfile?.manager?.lastName
                            )}
                      </Typography>
                    </Stack>
                  )}

                  <Stack direction={'row'} gap={1}>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.grey[400],
                      }}
                    >
                      Category:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={400}
                      sx={{
                        color: (theme) => theme.palette.grey[600],
                        overflow: 'hidden',
                        minWidth: 400,
                        width: 400,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {/* {selectedProfile?.categories?.map(
                        (val: any, index: number) => {
                          return (
                            <React.Fragment key={val?._id}>
                              {index > 0 && ', '}
                              {val?.name}
                            </React.Fragment>
                          );
                        }
                      )} */}

                      {selectedProfile?.categories?.map((val: any) => (
                        <View key={val?._id} style={styles.container}>
                          <Text>
                            {isMobileScreen && val?.name.length > 15
                              ? val?.name.substring(0, 15) + '...'
                              : val?.name}
                          </Text>
                        </View>
                      ))}
                    </Typography>
                  </Stack>
                </Box>
              )}
            </Grid>
            <Grid
              item
              sm={1}
              sx={{
                display: {
                  sm: isInfoModal ? 'flex' : 'none',
                  xs: 'none',
                  justifyContent: 'end',
                },
              }}
            >
              <Box
                onClick={() => {
                  onModalClose?.();
                }}
              >
                <CloseIcon />
              </Box>
            </Grid>

            <Grid item xs={12} sm={'auto'}>
              <Grid
                item
                xs="auto"
                gap={2}
                display={'flex'}
                sx={{
                  flexDirection: { sm: 'row', xs: 'column' },
                }}
                justifyContent={
                  !isProfilePage && isLarge ? 'flex-start' : 'flex-end'
                }
                py={(!isProfilePage && isLarge) || isExtraSmall ? 1 : 0}
                alignItems={'flex-start'}
                flexWrap={'wrap'}
              >
                {user.role === 'Manager' && !isProfilePage && false && (
                  <Button
                    startIcon={<MovingIcon fontSize={'small'} />}
                    onClick={handleAssignCourse}
                    variant="outlined"
                    sx={{
                      'width': { xs: '100%', sm: 'auto' },
                      'p': '3px 10px',
                      '&:hover': {
                        backgroundColor: (theme: any) =>
                          theme.palette.grey[300],
                      },
                      'minWidth': '149px',
                      'borderColor': (theme: any) => theme.palette.grey[500],
                      'order': 1,
                      'color': (theme) => theme.palette.grey[400],
                    }}
                  >
                    {textConst.assignCourse}
                  </Button>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{
                      width: { xs: '100%', sm: 'auto' },
                      p: '1px 10px',
                      order: isMedium ? 3 : 2,
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          color: (theme) => theme.palette.common.white,
                          fontSize: 16,
                        }}
                      />
                    </IconButton>
                    Back
                  </Button>
                </Box>

                {param?.userId ? (
                  <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    <PDFDownloadLink
                      style={{ width: '100%' }}
                      document={
                        <GenerateProfilePdf
                          selectedProfile={selectedProfile}
                          userSummaryData={userSummaryData}
                          courseData={allCourseData?.courses}
                        />
                      }
                      fileName="profile"
                    >
                      {({ loading }) => (
                        <Button
                          startIcon={<DownloadIcon fontSize={'small'} />}
                          onClick={handleDownloadProfile}
                          variant="outlined"
                          sx={{
                            width: { xs: '100%', sm: 'auto' },
                            p: '3px 10px',
                            order: isMedium ? 3 : 2,
                          }}
                        >
                          {loading
                            ? 'loading '
                            : textConst.downloadStandardProfile}
                        </Button>
                      )}
                    </PDFDownloadLink>
                  </Box>
                ) : (
                  <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    <PDFDownloadLink
                      style={{ width: '100%' }}
                      document={
                        <GenerateUserProfile
                          selectedProfile={selectedProfile}
                          userSummaryData={userSummaryData}
                          courseData={allCourseData?.courses}
                        />
                      }
                      fileName="profile"
                    >
                      {({ loading }) => (
                        <Button
                          startIcon={<DownloadIcon fontSize={'small'} />}
                          onClick={handleDownloadProfile}
                          variant="outlined"
                          sx={{
                            width: { xs: '100%', sm: 'auto' },
                            p: '3px 10px',
                            order: isMedium ? 3 : 2,
                          }}
                        >
                          {loading
                            ? 'loading '
                            : textConst.downloadStandardProfile}
                        </Button>
                      )}
                    </PDFDownloadLink>
                  </Box>
                )}
                {/* {!param?.id && (
                  <Button
                    startIcon={<EditIcon fontSize={'small'} />}
                    onClick={() => {
                      if (isProfilePage) {
                        navigate('/profile/edit');
                      } else if (param?.managerId && param?.userId) {
                        navigate(
                          `/users/list/manager/${param?.managerId}/user/${param?.userId}/edit`
                        );
                      } else {
                        navigate(
                          isMyTeamPage
                            ? `/my-team/user/${param?.userId}/edit`
                            : `/users/list/user/${param?.userId}/edit`
                        );
                      }
                    }}
                    variant="outlined"
                    sx={{
                      'width': { xs: '100%', sm: 'auto' },
                      'p': '3px 10px',
                      '&:hover': {
                        backgroundColor: (theme: any) =>
                          theme.palette.grey[300],
                      },
                      'minWidth': '73px',
                      'borderColor': (theme: any) => theme.palette.grey[500],
                      'order': isMedium ? 2 : 3,
                      'color': (theme: any) => theme.palette.grey[400],
                    }}
                  >
                    {textConst.edit}
                  </Button>
                )} */}
              </Grid>
            </Grid>
          </Grid>

          {!isAdminRole && param?.userId && (
            <HookTextSummary
              rows={selectedProfile?.role?.name === 'Admin' ? undefined : 4}
              selectedProfile={selectedProfile}
              userSummaryData={userSummaryData}
            />
          )}

          {!isAdminRole && param?.userId ? (
            <>
              <CustomTabs tabs={tabs} />
              <CustomTabs tabs={adminTabs} />
            </>
          ) : (
            <CustomTabs tabs={tabs} />
          )}
        </>
      ) : (
        <Typography textAlign={'center'} my={2}>
          No data available
        </Typography>
      )}

      {openDeleteDialog && (
        <AlertDialog
          open={openDeleteDialog}
          handleClose={handleClose}
          handleSave={() => handleDelete(selectedRecord)}
          title={textConst.areYouSure}
          content={textConst.areYouSureContent}
          saveBtnText={textConst.delete}
        />
      )}

      {openShowDialog && (
        <AlertShowSummaryDialog
          open={openShowDialog}
          handleClose={handleCloseShow}
          selectedProfile={selectedProfile}
          title={textConst.summaryText}
          content={selectedIdRecord}
          saveBtnText={textConst.edit}
        />
      )}

      {openEditDialog && (
        <AlertEditSummaryDialog
          open={openEditDialog}
          handleClose={handleCloseEdit}
          selectedProfile={selectedProfile}
          title={textConst.editSummary}
          content={selectedEditRecord}
          selectedEditIdRecord={selectedEditIdRecord}
          selectedEditSummaryIdRecord={selectedEditSummaryIdRecord}
          saveBtnText={textConst.save}
        />
      )}

      {openDialog && (
        <AlertReminderDialog
          open={openDialog}
          handleClose={handleReminderClose}
          handleSave={handleSendRemiderMail}
          title={textConst.areYouSure}
          content={textConst.areYouSureRemainderContent}
          isLoading={isSendCourseRemainderLoading}
          saveBtnText={textConst.sendremindermail}
        />
      )}
    </Box>
  );
};

export default ProfileContent;
